import React from "react";
import { Helmet } from "react-helmet";

import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import Layout from "../components/Layout";
import Container from "../components/Container";

const ContactPage = () => {

    return (
        <Layout pageName="contact">
            <Helmet>
                <title>Contact</title>
            </Helmet>
            <Container maxWidth="md" type="content">
                <Box sx={{ mt: "2em" }}>
                    <Typography gutterBottom variant="h5" component="div">
                        Thank you for the opportunity to host you in our favorite place.
                    </Typography>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary">
                        Please feel free to reach out to Aimee Cruz at{" "}
                        <a href="mailto:aimee@valleguadalupebaja.com">
                            aimee@valleguadalupebaja.com
                        </a>{" "}
                        or send a text or call to{" "}
                        <a href="tel:+16193618571">+1-619-361-8571</a>.
                    </Typography>
                </Box>
                <Box sx={{ mt: 4 }}>
                    <hr />
                    <Typography variant="caption" component="pre">
                        Valle Guadalupe Baja {"\n"}
                        4967 Newport Ave.{"\n"}
                        Suite 236{"\n"}
                        San Diego, Ca 92107
                    </Typography>
                </Box>
            </Container>
        </Layout>
    );
};

export default ContactPage;
